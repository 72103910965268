import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Button from "../library/button/button"
import ContentWrapper from "../library/content-wrapper/content-wrapper"
import Section from "../library/section/section"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div style={{ background: `#eaeaea`, height: `800px` }}>
      <Section>
        <Button onClick={() => alert("Testing")} label="Click Me" />
      </Section>
    </div>
    <Section>
      <h1>Hi people</h1>
    </Section>
  </Layout>
)

export default IndexPage
