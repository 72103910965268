import React from "react"
import buttonStyles from "./button.module.scss"

function Button(props) {
  const { label, onClick } = props

  return (
    <button className={buttonStyles.button} onClick={onClick}>
      {label}
    </button>
  )
}

export default Button
