import React from "react"
import contentStyles from "./content-wrapper.module.scss"

function ContentWrapper(props) {
  const { fullWidth, children } = props

  return (
    <div
      className={
        fullWidth ? contentStyles.containerFullWidth : contentStyles.container
      }
    >
      {children}
    </div>
  )
}

export default ContentWrapper
